import * as React from 'react'
import Img from 'gatsby-image'
import { Catalog } from '../components/types'

export const getBaseCatalog = (
  catalog: Catalog,
  imageSources?: Record<string, any>,
  url?: string,
) => {
  return {
    ...catalog,
    categories: catalog.categories.map(category => {
      category.url = url

      if (imageSources) {
        category.services.forEach(service => {
          if (service.image && typeof service.image === 'string') {
            if (!imageSources[service.image]) {
              return
            }

            const fluid = imageSources[service.image].childImageSharp.fluid
            service.imageURL = fluid.src
            service.image = (
              <Img
                alt={service.name}
                style={{ height: '100%' }}
                loading={'eager'}
                fluid={fluid}
              />
            )
          }
        })
      }

      return category
    }),
  }
}
